import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutFormComponent } from './checkout-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemModule } from '../product-item';
import { RouterModule } from '@angular/router';
import { HelpQuestionMarkModule } from '../help-question-mark/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckoutFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ProductItemModule,
    RouterModule,
    HelpQuestionMarkModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CheckoutFormComponent]
})
export class CheckoutFormModule { }
