import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'product',
  path_id: 'product_id',
})

export class Product extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'name_ge',
  })
  public name_ge!: string;

  @Field({
    is_required: true,
    field_name: 'name_en',
  })
  public name_en!: string;

  @Field({
    is_required: true,
    field_name: 'name_es',
  })
  public name_es!: string;

  @Field({
    is_required: true,
    field_name: 'desc_ge',
  })
  public desc_ge!: string;

  @Field({
    is_required: true,
    field_name: 'desc_en',
  })
  public desc_en!: string;

  @Field({
    is_required: true,
    field_name: 'desc_es',
  })
  public desc_es!: string;

  @Field({
    is_required: true,
    field_name: 'category',
  })
  public category!: string;

  @Field({
    is_required: false,
    field_name: 'disabled',
  })
  public disabled: boolean = false;

  @Field({
    is_required: false,
    field_name: 'sale',
  })
  public sale: number = 0;

  @Field({
    is_required: false,
    field_name: 'viewed',
  })
  public viewed: number = 0;

  @Field({
    is_required: false,
    field_name: 'featured',
  })
  public featured: boolean = false;

  @Field({
    is_required: false,
    field_name: 'mainImage',
  })
  public mainImage!: string;

  @Field({
    is_required: false,
    field_name: 'images',
  })
  public images: string[] = [];

}
