import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'page',
  path_id: 'page_id',
})

export class Page extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'name',
  })
  public name!: string;

  @Field({
    is_required: false,
    field_name: 'content_en',
  })
  public content_en!: string;

  @Field({
    is_required: false,
    field_name: 'content_ge',
  })
  public content_ge!: string;

  @Field({
    is_required: false,
    field_name: 'content_es',
  })
  public content_es!: string;

  @Field({
    is_required: false,
    field_name: 'image',
  })
  public image!: string;
}