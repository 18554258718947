import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup } from "@angular/forms";
import { Countries } from '@enums';
import { HelperService } from '@services';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss']
})
export class CheckoutFormComponent implements OnInit {
  @Input() lang: string = 'en';
  @Input() country: string = 'GE';
  @Input() set clickedCheckout(val) {
    if (val) {
      this.onCheckout();
    }
  };
  form: FormGroup;
  showCountryDropdown: boolean = false;
  countries: any = HelperService.enumToObject(Countries);

  @Output() outCountry = new EventEmitter<string>();
  @Output() outFormDate = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.id !== 'country-dropdown' && event.target.id !== 'current') {
      this.showCountryDropdown = false;
    }
  }

  onShowCountry() {
    this.showCountryDropdown = !this.showCountryDropdown;
  }

  onChooseCountry(key: string): void {
    this.form.patchValue({ country: key });
    if (key === 'GE') {
      this.form.controls['address2'].disable();
      this.form.controls['state'].disable();
      this.form.controls['zip'].disable();
    } else {
      this.form.controls['address2'].enable();
      this.form.controls['state'].enable();
      this.form.controls['zip'].enable();
    }
    this.outCountry.emit(key);
  }

  onCheckout(): any {
    this.outFormDate.emit(this.form);
  }

  onOpenPage(page: string): void {
    HelperService.goToLink(page);
  }

  get f() { return this.form.controls; }

  createForm(): void {
    this.form = this.formBuilder.group({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.email, Validators.required]),
      phone: new FormControl(null, Validators.required),
      country: new FormControl(Countries.Georgia, Validators.required),
      city: new FormControl(null, Validators.required),
      address1: new FormControl(null, Validators.required),
      address2: new FormControl({ value: null, disabled: true }),
      state: new FormControl({ value: null, disabled: true }, Validators.required),
      zip: new FormControl({ value: null, disabled: true }, Validators.required),
      comment: new FormControl(null),
      card: new FormControl(null),
      agree: new FormControl(false)
    });
  }

}
