<section class="shop-blog section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>{{ 'From Our Blog' | translate }}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12" *ngFor="let blog of blogs">
                <!-- Start Single Blog  -->
                <div class="shop-single-blog">
                    <img [src]="blog.image" alt="#" class="img">
                    <div class="content">
                        <a (click)="goToLink(blog.link)" class="title">{{ blog['name_' + lang] }}</a>
                        <a (click)="goToLink(blog.link)" class="more-btn">{{ 'Continue Reading' | translate }}</a>
                    </div>
                </div>
                <!-- End Single Blog  -->
            </div>
        </div>
    </div>
</section>