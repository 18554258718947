import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutTotalComponent } from './checkout-total.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemModule } from '../product-item';
import { PipesModule } from '@pipes';
import { HelpQuestionMarkModule } from '../help-question-mark/help-question-mark.module';

@NgModule({
  declarations: [CheckoutTotalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ProductItemModule,
    HelpQuestionMarkModule,
    PipesModule
  ],
  exports: [CheckoutTotalComponent]
})
export class CheckoutTotalModule { }
