import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'app/services';

@Pipe({ name: 'currency', pure: false })
export class CurrencyPipe implements PipeTransform {
  currencies: any = {};
  currenctCurrency: string = 'usd';

  constructor(private currencyService: CurrencyService) {
    this.currencyService.currency$.subscribe(res => {
      this.currencies = res;
    });

    this.currencyService.currentCurrency$.subscribe(res => {
      this.currenctCurrency = res;
    });
   }
   
  transform(price: number) { 
    if (!price) return price;
    let transformedDate: string = '';
    
    switch (this.currenctCurrency) {
      case 'usd':
        transformedDate = '$' + (price * this.currencies.usd).toFixed(2);
        break;

      case 'gel':
        transformedDate = '₾' + price;
        break;

      case 'euro':
        transformedDate = '€' + (price * this.currencies.euro).toFixed(2);
        break;
    
      default:
        break;
    }  
    return transformedDate; 
  }
}