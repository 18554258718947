<table class="table shopping-summery">
    <thead>
        <tr class="main-hading">
            <th>{{ 'PRODUCT' | translate }}</th>
            <th>{{ 'NAME' | translate }}</th>
            <th class="text-center">{{ 'UNIT PRICE' | translate }}</th>
            <th class="text-center">{{ 'QUANTITY' | translate }}</th>
            <th class="text-center">{{ 'TOTAL' | translate }}</th> 
            <th class="text-center"><i class="ti-trash remove-icon"></i></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of cartItems; let i = index">
            <td class="image" data-title="No"><img [src]="item.data.mainImage" alt="#"></td>
            <td class="product-des" data-title="Description">
                <p class="product-name"><a href="#">{{ item.data[nameTr] }}</a></p>
                <p class="product-des" *ngIf="item.color !== 'without_color'">{{ 'Color' | translate }} - <span>>{{ getColorName(item.color) }}</span></p>
            </td>
            <td class="price" data-title="Price"><span>{{ item.price | currency }} </span></td>
            <td class="qty" data-title="Qty"><!-- Input Order -->
                <div class="input-group">
                    <div class="button minus">
                        <button 
                            type="button" 
                            class="btn btn-primary btn-number" 
                            [disabled]="item.qty === 1"
                            (click)="onChangeQuantity(i, 'minus')">
                            <i class="ti-minus"></i>
                        </button>
                    </div>
                    <input type="text" name="quant[1]" class="input-number" [value]="item.qty">
                    <div class="button plus">
                        <button 
                            type="button" 
                            class="btn btn-primary btn-number" 
                            [disabled]="item.productQty === item.qty"
                            (click)="onChangeQuantity(i, 'plus')">
                            <i class="ti-plus"></i>
                        </button>
                    </div>
                </div>
                <!--/ End Input Order -->
            </td>
            <td class="total-amount" data-title="Total"><span>{{ item.price * item.qty | currency }}</span></td>
            <td class="action" data-title="Remove"><a (click)="onRemoveFromCart(item.optionId)"><i class="ti-trash remove-icon"></i></a></td>
        </tr>
    </tbody>
</table>