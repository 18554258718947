import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrendingComponent } from './trending.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemModule } from '../product-item';

@NgModule({
  declarations: [TrendingComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ProductItemModule
  ],
  exports: [TrendingComponent]
})
export class TrendingModule { }
