import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarContainer } from './sidebar.container';
import { NavigationModule } from '@components';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SidebarContainer
  ],
  imports: [
    CommonModule,
    NavigationModule,
    TranslateModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    SidebarContainer
  ]
})

export class SidebarModule { }
