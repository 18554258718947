import { Component } from '@angular/core';
import * as firebase from "firebase/app";
import { FirestoreOrmRepository } from "@arbel/firebase-orm";
import { environment } from "../environments/environment";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Creadora';

  constructor(public translate: TranslateService) {
    // initialize firebase orm
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const connection = firebaseApp.firestore();
    // @ts-ignore
    FirestoreOrmRepository.initGlobalConnection(connection);

    translate.addLangs(['en', 'ge', 'es']);
    translate.setDefaultLang('en');
  }

}
