import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductModalComponent } from './product-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SlideshowModule } from 'ng-simple-slideshow';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'app/pipes';

@NgModule({
  declarations: [ProductModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SlideshowModule,
    RouterModule,
    PipesModule
  ],
  exports: [ProductModalComponent]
})
export class ProductModalModule { }
