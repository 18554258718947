<div class="modal fade" [class.show]="open" id="exampleModal" tabindex="-1" role="dialog" ria-hidden="true" >
    <div class="product-modal-background" (click)="onClose($event)">
    </div>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button (click)="onClose($event)" type="button" class="close" data-dismiss="modal" aria-label="Close"><span class="ti-close" aria-hidden="true"></span></button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <!-- Product Slider -->
                            <div class="product-gallery">
                                <div class="quickview-slider-active owl-carousel owl-theme owl-loaded">
                                <div class="owl-stage-outer">
                                    <div class="owl-stage">
                                        <slideshow 
                                            *ngIf="product.images.length"
                                            class="slideshow"
                                            #slideshow
                                            [height]="'510px'"
                                            [autoPlay]="false"
                                            [showArrows]="false"
                                            [showDots]="true"
                                            [showCaptions]="true"
                                            [dotColor]="'#ff7430'"
                                            [captionColor]="'#ff7430'"
                                            [imageUrls]="product.images"
                                            [lazyLoad]="product.images.length > 1"
                                            [backgroundSize]="'100% 100%'"
                                            [autoPlayWaitForLazyLoad]="true">
                                            </slideshow>
                                        </div>
                                    </div>
                                    <div class="owl-controls">
                                        <div class="owl-nav">
                                            <div class="owl-prev" (click)="onSlideChange('+')">
                                                <i class=" ti-arrow-left"></i>
                                            </div>
                                            <div class="owl-next" (click)="onSlideChange('-')">
                                                <i class=" ti-arrow-right"></i>
                                            </div>
                                        </div>
                                        <div class="owl-dots" style="display: none;"></div>
                                    </div>
                                </div>
                            </div>
                        <!-- End Product slider -->
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div class="quickview-content">
                            <h2>{{ product.name_en }}</h2>
                            <div class="quickview-ratting-review">
                                <div class="quickview-stock">
                                    <span *ngIf="selectedOption?.quantity > 0">
                                        <i class="fa fa-check-circle-o"></i> {{ 'In stock' | translate }}
                                    </span>
                                    <span class="out" *ngIf="selectedOption?.quantity === 0">
                                        <i class="fa fa-times-circle-o"></i> {{ 'Out of stock' | translate }}
                                    </span>
                                    <span>
                                        <i class="fa fa-certificate"></i> {{ selectedOption?.code }}
                                    </span>
                                    <span class="featured" *ngIf="product.featured">{{ 'Featured' | translate }}</span>
                                    <span class="sale" *ngIf="product.sale">{{product.sale}}% Off</span>
                                </div>
                            </div>
                            <h3>{{ selectedOption?.price | currency }}</h3>
                            <div class="quickview-peragraph" [innerHTML]="product[descriptionTr]">
                            </div>
                            <div class="size" *ngIf="selectedOption?.quantity > 0 && selectedOption.color !== 'without_color'">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <div 
                                            class="nice-select" 
                                            [class.nice-select-err]="error"
                                            tabindex="0" 
                                            [class.open]="showColorDropDown"
                                            (click)="showColorDropDown = !showColorDropDown">
                                            <span class="current">{{ selectedCol[colorTr] | translate }}</span>
                                            <ul class="list">
                                                <li 
                                                    class="option" 
                                                    *ngFor="let color of colors"
                                                    (click)="onChooseColor(color)">{{ color[colorTr] }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="quantity" *ngIf="selectedOption?.quantity > 0">
                                <!-- Input Order -->
                                <div class="input-group">
                                    <div class="button minus">
                                        <button 
                                            type="button" 
                                            class="btn btn-primary btn-number" 
                                            [disabled]="quantity === 1" 
                                            (click)="onChangeQuantity('minus')">
                                            <i class="ti-minus"></i>
                                        </button>
                                    </div>
                                    <input type="text" class="input-number" [value]="quantity">
                                    <div class="button plus">
                                        <button 
                                            type="button" 
                                            [disabled]="selectedOption?.quantity === quantity" 
                                            class="btn btn-primary btn-number" 
                                            (click)="onChangeQuantity('plus')">
                                            <i class="ti-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!--/ End Input Order -->
                            </div>
                            <div class="add-to-cart" *ngIf="selectedOption?.quantity > 0">
                                <a class="btn" (click)="onAddCart(product)">{{ 'Add to cart' | translate }}</a>
                                <!-- <a class="btn min" (click)="onFavorited(product)"><i class="ti-heart"></i></a> -->
                                <!-- <a [routerLink]="['/shop', product.id]" class="btn min"><i class="fa fa-compress"></i></a> -->
                            </div>
                            <!-- <div class="default-social">
                                <h4 class="share-now">Share:</h4>
                                <ul>
                                    <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                                    <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                                    <li><a class="youtube" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    <li><a class="dribbble" href="#"><i class="fa fa-google-plus"></i></a></li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>