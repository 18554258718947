import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.scss']
})
export class TrendingComponent implements OnInit {
  @Input() products: any[] = [];
  @Input() lang: string = 'en';

  @Output() clickView = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    
  }

  onView(id: string): void {
    this.clickView.emit(id);
  }

}
