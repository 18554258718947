import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Color, Product, ProductOption } from '@models';
import { CartService } from '@services';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent implements OnInit {
  @ViewChild('slideshow') slideshow: any;
  @Input() product: any = {};
  @Input() lang: string = 'en';
  @Output() clickClose = new EventEmitter<boolean>();
  error: boolean = false;
  open: boolean = false;
  showColorDropDown: boolean = false;
  colors: any[] = [];
  quantity: number = 1;
  descriptionTr: string = 'desc_' + this.lang;
  colorTr: string = 'name_' + this.lang;
  productOptions: ProductOption[] = [];
  selectedOption: ProductOption;
  selectedCol: any = {};

  constructor(private cartService: CartService) { }

  async ngOnInit() {
    this.product.images.unshift(this.product.mainImage)
    this.colorTr = 'name_' + this.lang;
    this.descriptionTr = 'desc_' + this.lang;

    setTimeout(() => {
      this.open = true;
    });
    
    this.productOptions = await ProductOption.find('product','==',this.product.id);
    
    for (let i = 0; i < this.productOptions.length; i++) {
      const color = await Color.init(this.productOptions[i].color);
      this.colors.push(color);
      if (i === 0) {
        this.selectedOption = this.productOptions[i];
        this.selectedCol = color;
      }
    }
  }

  onClose(e): void {
    e.stopPropagation();
    this.clickClose.emit(true);
  }

  onChooseColor(color: Color): void {
    this.error = false;
    this.selectedCol = color;
    this.selectedOption = this.productOptions.find(opt => opt.color === color.id);
  }

  onChangeQuantity(option: string): void {
    if (option === 'plus' && 
        this.quantity === this.product.quantity || 
        option === 'minus' && this.quantity === 1) {
      return;
    }

    option === 'plus' ? this.quantity += 1 : this.quantity -= 1;
  }

  onAddCart(product: Product): any {
    if (!this.selectedCol ) {
      return this.error = true;
    }

    const payload = {
      [this.selectedOption.id]: this.quantity
    }
    let cart: any = JSON.parse(localStorage.getItem('credoraCart'));
    if (cart) {
      cart = { ...cart, ...payload }
      localStorage.setItem('credoraCart', JSON.stringify(cart));
      this.cartService.getCartProducts(cart);
    } else {
      localStorage.setItem('credoraCart', JSON.stringify(payload));
      this.cartService.getCartProducts(payload);
    }

    this.clickClose.emit(true);
  }

  onSlideChange(option: string): void {
    this.slideshow.onSlide(option === '-' ? 1 : -1);
  }

}
