import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'order',
  path_id: 'order_id',
})

export class Order extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'product',
  })
  public product!: any;

  @Field({
    is_required: true,
    field_name: 'payment',
  })
  public payment!: string;

  @Field({
    is_required: false,
    field_name: 'comment',
  })
  public comment!: string;
  
  @Field({
    is_required: false,
    field_name: 'card',
  })
  public card!: string;

  @Field({
    is_required: true,
    field_name: 'address1',
  })
  public address1!: string;

  @Field({
    is_required: false,
    field_name: 'address2',
  })
  public address2!: string;

  @Field({
    is_required: false,
    field_name: 'state',
  })
  public state!: string;

  @Field({
    is_required: true,
    field_name: 'city',
  })
  public city!: string;

  @Field({
    is_required: false,
    field_name: 'zip',
  })
  public zip!: string;

  @Field({
    is_required: true,
    field_name: 'totalPrice',
  })
  public totalPrice!: number;

  @Field({
    is_required: true,
    field_name: 'currency',
  })
  public currency!: string;
  
  @Field({
    is_required: true,
    field_name: 'phone',
  })
  public phone!: string;

  @Field({
    is_required: true,
    field_name: 'country',
  })
  public country!: string;

  @Field({
    is_required: true,
    field_name: 'email',
  })
  public email!: string;

  @Field({
    is_required: true,
    field_name: 'firstname',
  })
  public firstname!: string;

  @Field({
    is_required: true,
    field_name: 'lastname',
  })
  public lastname!: string;

  @Field({
    is_required: false,
    field_name: 'shipping',
  })
  public shipping: number = 0;

  @Field({
    is_required: false,
    field_name: 'status',
  })
  public status: string = 'pending';
}
