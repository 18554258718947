<header class="header shop" [class.sticky]="sticky">
  <div class="middle-inner">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-12">
          <!-- Logo -->
          <div class="logo">
            <a href="index.html"><img src="/assets/images/logo.png" alt="logo"></a>
          </div>
          <!--/ End Logo -->
          <!-- Search Form -->
          <div class="search-top" [class.active]="showMobileSearch">
            <div class="top-search">
              <a (click)="showMobileSearch = !showMobileSearch"><i class="ti-search"></i></a>
              <a (click)="showMobileMenu = !showMobileMenu"><i class="ti-menu"></i></a>
            </div>
            
            <!-- Search Form -->
            <div class="search-top mobile-search" [class.active]="showMobileSearch">
              <form class="search-form">
                <input type="text" placeholder="Search here..." name="search" (input)="onSearch($event)">
                <button value="search" type="buttom"><i class="ti-search"></i></button>
              </form>
              <div class="search-result search-result--mobile" id="search-result" *ngIf="showMobileSearch">
                <div id="search-result-item" class="search-result-item" *ngFor="let product of searchProductList"
                  (click)="getSelectedProduct(product)">
                  <img [src]="product.mainImage" alt="No Image" /> {{ product[categoryTr] }}
                </div>
              </div>
            </div>
            <!--/ End Search Form -->
          </div>
          <!--/ End Search Form -->
          <div class="mobile-nav">
            <div class="slicknav_menu">
              <ul class="slicknav_nav"
              [class.slicknav_hidden]="!showMobileMenu" 
              aria-hidden="!showMobileMenu" 
              role="menu" 
              [ngStyle]="{display: showMobileMenu ? 'block' : 'none'}">
              <li *ngFor="let item of navigation" class="mobile-menu-item">
                <a (click)="outClickedMenu(item)" [routerLinkActive]="item.url">
                {{ 'MENU.' + item.label | translate }}
                </a>
              </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-7 col-12">
          <div class="search-bar-top">
            <div class="search-bar">
              <div class="nice-select cat-select" id="cat-dropdown" [class.open]="onShowAllCat" tabindex="0"
                (click)="onShowAllCat = !onShowAllCat">
                <span class="current"
                  id="current">{{ (selectedCat === 'all' ? 'All Category' : selectedCat['name_' + lang]) | translate }}</span>
                <ul class="list">
                  <li data-value="All Category" class="option" (click)="onChooseCategory('all')">
                    {{ 'All Category' | translate }}</li>
                  <li data-value="watch" class="option" *ngFor="let cat of categories" (click)="onChooseCategory(cat)">
                    {{ cat['name_' + lang] }}
                  </li>
                </ul>
              </div>
              <form>
                <input name="searching" placeholder="{{ 'Search Products Here' | translate }}....." type="search"
                  (input)="onSearch($event)">
                <button class="btnn"><i class="ti-search"></i></button>
                <div class="search-result" id="search-result">
                  <div id="search-result-item" class="search-result-item" *ngFor="let product of searchProductList"
                    (click)="getSelectedProduct(product)">
                    <img [src]="product.mainImage" alt="No Image" /> {{ product[categoryTr] }}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-12">
          <div class="right-bar">
            <!-- Search Form -->
            <div class="sinlge-bar" *ngIf="lang !=='ge'">
              <a (click)="onChangeLang('ge')" class="single-icon">
                <img src="/assets/images/ge.png" alt="No Icon" />
              </a>
            </div>
            <div class="sinlge-bar" *ngIf="lang !=='en'">
              <a (click)="onChangeLang('en')" class="single-icon">
                <img src="/assets/images/en.png" alt="No Icon" />
              </a>
            </div>
            <div class="sinlge-bar" *ngIf="lang !=='es'">
              <a (click)="onChangeLang('es')" class="single-icon">
                <img src="/assets/images/es.png" alt="No Icon" />
              </a>
            </div>
            <div class="currency">
              <div class="nice-select" [class.open]="currencyDropDown" tabindex="0"
                (click)="currencyDropDown = !currencyDropDown">
                <span class="current">{{ currencyOption[selectedCurrency] }}</span>
                <ul class="list">
                  <li data-value="watch" class="option" (click)="onChangeCurrency('usd')">
                    {{ '$' }}
                  </li>
                  <li data-value="watch" class="option" (click)="onChangeCurrency('gel')">
                    {{ '₾' }}
                  </li>
                  <li data-value="watch" class="option" (click)="onChangeCurrency('euro')">
                    {{ '€' }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="sinlge-bar shopping">
              <a class="single-icon"><i class="ti-bag"></i> <span class="total-count">{{ cartItems.length }}</span></a>
              <!-- Shopping Item -->
              <div class="shopping-item">
                <div class="dropdown-cart-header">
                  <span>{{ cartItems.length }} </span>
                  <span>{{ 'Items' | translate }} </span>
                  <a [routerLink]="['/cart']">{{ 'View Cart' | translate }}</a>
                </div>
                <ul class="shopping-list">
                  <li *ngFor="let item of cartItems">
                    <a class="remove" (click)="onRemoveFromCart(item.optionId)"><i class="fa fa-remove"></i></a>
                    <a class="cart-img"><img [src]="item.data.mainImage" alt="#"></a>
                    <h4><a href="#">{{ item.data['name' + lang] }}</a></h4>
                    <p class="quantity">{{ item.qty }} - <span class="amount">{{ item.price | currency }}</span></p>
                    <p class="quantity color" *ngIf="item.color !== 'without_color'">
                      {{ 'Color' | translate }} - <span class="amount">{{ getColorName(item.color) }}</span>
                    </p>
                  </li>
                </ul>
                <div class="bottom">
                  <div class="total">
                    <span>{{ 'Total' | translate }}</span>
                    <span class="total-amount">{{ cartTotalPrice | currency }}</span>
                  </div>
                  <a [routerLink]="['/cart']" class="btn animate">{{ 'Checkout' | translate }}</a>
                </div>
              </div>
              <!--/ End Shopping Item -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar [lang]="lang"></app-sidebar>
</header>
<app-product-modal *ngIf="productModal" [lang]="lang" [product]="selectedProduct" (clickClose)="productModal = false">
</app-product-modal>