<div class="order-details">
    <!-- Order Widget -->
    <div class="single-widget">
        <h2>{{ 'CART TOTALS' | translate }}</h2>
        <div class="content">
            <ul>
                <li>{{ 'Sub Total' | translate }}<span>{{ subtotal | currency }}</span></li>
                <li>(+) {{ 'Shipping' | translate }}<span>{{ shipping | currency }}</span></li>
                <li class="last">{{ 'Total' | translate }}<span>{{ subtotal + shipping | currency }}</span></li>
            </ul>
        </div>
    </div>
    <!--/ End Order Widget -->
    <!-- Order Widget -->
    <div class="single-widget">
        <h2>{{ 'Payments' | translate }}</h2>
        <div class="content">
            <div class="checkbox">
                <label 
                    class="checkbox-inline" 
                    for="1" 
                    (click)="paymentMethod = payments.CASH"
                    [class.checked]="paymentMethod === payments.CASH">
                    <input 
                        name="cash" 
                        id="1" 
                        type="checkbox"
                        [value]="paymentMethod === payments.CASH"
                        (change)="onChoosePaymenth(payments.CASH)"> {{ 'Cash On Delivery' | translate }}
                </label>
                <label class="checkbox-inline bank-transfer" for="2" [class.checked]="paymentMethod === payments.BANK">
                    <input 
                        name="bank" 
                        type="checkbox" 
                        id="2"
                        (click)="paymentMethod = payments.BANK"
                        [value]="paymentMethod === payments.BANK"
                        (change)="onChoosePaymenth(payments.BANK)"> {{ 'Bank Transfer' | translate }}
                    <!-- <app-help-question-mark 
                        [leftDirection]="true"
                        [text]="'Please indicate the following order number in transaction description: [Order ID]'">
                    </app-help-question-mark> -->
                </label>
                <label class="checkbox-inline" for="3" [class.checked]="paymentMethod === payments.PAYPAL">
                    <input 
                        name="paypal" 
                        type="checkbox" 
                        id="3"
                        (click)="paymentMethod = payments.PAYPAL"
                        [value]="paymentMethod === payments.PAYPAL"
                        (change)="onChoosePaymenth(payments.PAYPAL)"> {{ 'PayPal' | translate }}
                </label>
            </div>
            <!-- <div class="payment-info">
                <ng-container [ngSwitch]="paymentMethod">
                    <ng-container *ngSwitchCase="payments.PAYPAL">
                        {{ 'ID for payment:' | translate }} <span>{{ paypal }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="payments.CASH">
                        {{ 'Hold a cash with delivery service' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="payments.BANK">
                        <span class="bank" (click)="openBankDetails()">
                            {{ 'For bank transfer info please see attached file' | translate }}
                        </span>
                    </ng-container>
                 </ng-container>
            </div> -->
        </div>
    </div>
    <!--/ End Order Widget -->
    <!-- Button Widget -->
    <div class="single-widget get-button">
        <div class="content">
            <div class="button">
                <a (click)="onCheckout()" class="btn">{{ 'COMPLETE ORDER' | translate }}</a>
            </div>
        </div>
        <div class="error" *ngIf="error">{{ error | translate }}</div>
    </div>
    <!--/ End Button Widget -->
</div>