<div class="breadcrumbs">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="bread-inner">
                    <ul class="bread-list">
                        <li *ngFor="let item of breadcrum; let i = index" [class.active]="i === breadcrum.length - 1">
                            <a [routerLink]="['/', item.url]" >{{ 'MENU.' + item.name | translate }}
                                <i *ngIf="i !== breadcrum.length - 1" class="ti-arrow-right"></i>
                            </a>
                        </li>
                        <!-- <li class="active"><a href="blog-single.html">Shop Grid</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>