import { Component, OnInit, Input } from '@angular/core';
import { Color } from '@models';
import { CartService } from 'app/services';

@Component({
  selector: 'app-cart-listing',
  templateUrl: './cart-listing.component.html',
  styleUrls: ['./cart-listing.component.scss']
})
export class CartListingComponent implements OnInit {
  @Input() cartItems: any[] = [];
  @Input() lang: string = 'en';

  nameTr = 'name_' + this.lang;
  colors: any;

  constructor(private cartService: CartService) {
    Color.getAll().then(col => {
      this.colors = col;
    })
   }

  ngOnInit(): void {
    this.nameTr = 'name_' + this.lang;
  }

  getColorName(colorId: string) {
    return this.colors.find(col => col.id === colorId)[this.nameTr];
  }

  onRemoveFromCart(optionId: string) {
    this.cartService.removeFromCart(optionId);
  }

  onChangeQuantity(index: number, option: string): void {
    if (option === 'plus' && 
        this.cartItems[index].productQty === this.cartItems[index].qty || 
        option === 'minus' && this.cartItems[index].qty === 1) {
      return;
    }

    let newQty: number = 1;
    newQty = option === 'plus' ? this.cartItems[index].qty += 1 : this.cartItems[index].qty -= 1;
    const payload = {
      [this.cartItems[index].optionId]: newQty 
    }
    let cart: any = JSON.parse(localStorage.getItem('credoraCart'));
    if (cart) {
      cart = { ...cart, ...payload }
      localStorage.setItem('credoraCart', JSON.stringify(cart));
      this.cartService.getCartProducts(cart);
    } else {
      localStorage.setItem('credoraCart', JSON.stringify(payload));
      this.cartService.getCartProducts(payload);
    }

  }

}
