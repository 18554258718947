import { Component, OnInit  } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.container.html',
  styleUrls: ['./wrapper.container.scss'],
})

export class WrapperContainer implements OnInit {
  lang: string = 'en';
  constructor(public translate: TranslateService,
              private router: Router) {
    this.lang = this.translate.getDefaultLang();
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
   }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
}
