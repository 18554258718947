<section class="shop-services section home">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Start Single Service -->
                <div class="single-service">
                    <img src="/assets/images/service-price.png" class="single-service-icon" alt="no image">
                    <h4>{{ 'SERVICE.Best_price_for_you' | translate }}</h4>
                    <p>{{ 'SERVICE.Guaranteed_price' | translate }}</p>
                </div>
                <!-- End Single Service -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Start Single Service -->
                <div class="single-service">
                    <img src="/assets/images/service-shipping.png" class="single-service-icon" alt="no image">
                    <h4>{{ 'SERVICE.Free_Shipping' | translate }}</h4>
                    <p>{{ 'SERVICE.Across_georgia' | translate }}</p>
                </div>
                <!-- End Single Service -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Start Single Service -->
                <div class="single-service">
                    <img src="/assets/images/service-branded.png" class="single-service-icon" alt="no image">
                    <h4>{{ 'SERVICE.Branded_package' | translate }}</h4>
                    <p>{{ 'SERVICE.Free_on_every_order' | translate }}</p>
                </div>
                <!-- End Single Service -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Start Single Service -->
                <div class="single-service">
                    <img src="/assets/images/service-design.png" class="single-service-icon" alt="no image">
                    <h4>{{ 'SERVICE.Unique_design' | translate }}</h4>
                    <p>{{ 'SERVICE.Think_about_every_detail' | translate }}</p>
                </div>
                <!-- End Single Service -->
            </div>
        </div>
    </div>
</section>