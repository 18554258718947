import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Payments } from '@enums';
import { HelperService } from 'app/services';

@Component({
  selector: 'app-checkout-total',
  templateUrl: './checkout-total.component.html',
  styleUrls: ['./checkout-total.component.scss']
})
export class CheckoutTotalComponent implements OnInit {
  @Input() lang: string = 'en';
  @Input() subtotal: number = 0;
  @Input() shipping: number = 0;
  @Input() paymentMethod: string;
  @Input() paypal: string;
  @Input() error: string = '';
  payments = Payments;

  @Output() clickCheckout = new EventEmitter<boolean>();
  @Output() choosedPaymenth = new EventEmitter<string>();

  constructor() {
    
   }

  async ngOnInit() {
  }

  onChoosePaymenth(e: any): void {
    this.choosedPaymenth.emit(e);
  }

  onCheckout(): void {
    this.clickCheckout.emit(true);
  }

  openBankDetails(): void {
    HelperService.goToLink('/assets/files/bank.pdf')
  }

}
