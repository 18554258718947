import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'category',
  path_id: 'category_id',
})

export class Category extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'name_ge',
  })
  public name_ge!: string;

  @Field({
    is_required: true,
    field_name: 'name_en',
  })
  public name_en!: string;

  @Field({
    is_required: true,
    field_name: 'name_es',
  })
  public name_es!: string;
}
