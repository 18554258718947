import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'users',
  path_id: 'user_id',
})

export class User extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'email',
  })
  public email!: string;

  @Field({
    is_required: true,
    field_name: 'firstname',
  })
  public firstname!: string;

  @Field({
    is_required: true,
    field_name: 'lastname',
  })
  public lastname!: string;

  @Field({
    is_required: true,
    field_name: 'city',
  })
  public city!: string;
  
  @Field({
    is_required: true,
    field_name: 'address',
  })
  public address!: string;

  @Field({
    is_required: true,
    field_name: 'phone',
  })
  public phone!: string;

  @Field({
    is_required: true,
    field_name: 'role',
  })
  public role: number = 1;
}