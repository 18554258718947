import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INavItem } from '@interfaces';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() navigation: Array<INavItem>;
  @Output() outClickedItem = new EventEmitter<INavItem>()

  ngOnInit(): void {
  }

}
