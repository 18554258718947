<form class="form" [formGroup]="form">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'First Name' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="firstname" 
                    placeholder="" 
                    [class.invalid]="f.firstname.touched && f.firstname.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'Last Name' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="lastname" 
                    placeholder="" 
                    [class.invalid]="f.lastname.touched && f.lastname.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'Email Address' | translate}}<span>*</span></label>
                <input 
                    type="email" 
                    formControlName="email" 
                    placeholder="" 
                    [class.invalid]="f.email.touched && f.email.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'Phone Number' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="phone" 
                    placeholder="" 
                    [class.invalid]="f.phone.touched && f.phone.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'Country' | translate}}<span>*</span></label>
                <div 
                    class="nice-select" 
                    id="country-dropdown"
                    [class.open]="showCountryDropdown"
                    (click)="onShowCountry()">
                    <span class="current" id="current">{{ countries[form.value.country] }}</span>
                    <ul class="list">
                        <li 
                            *ngFor="let country of countries  | keyvalue" 
                            (click)="onChooseCountry(country.key)" 
                            class="option">{{ country.value }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12" *ngIf="country !== 'GE'">
            <div class="form-group">
                <label>{{'State' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="state" 
                    placeholder="" 
                    [class.invalid]="f.state.touched && f.state.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'City' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="city" 
                    placeholder="" 
                    [class.invalid]="f.city.touched && f.city.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12" *ngIf="country !== 'GE'">
            <div class="form-group">
                <label>{{'Postal Code' | translate}}<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="zip" 
                    placeholder="" 
                    [class.invalid]="f.zip.touched && f.zip.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label>{{'Address Line' | translate}}  1<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="address1" 
                    placeholder="" 
                    [class.invalid]="f.address1.touched && f.address1.errors"
                    required="required">
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12" *ngIf="country !== 'GE'">
            <div class="form-group">
                <label>{{'Address Line' | translate}} 2<span>*</span></label>
                <input 
                    type="text" 
                    formControlName="address2" 
                    placeholder="" 
                    [class.invalid]="f.address2.touched && f.address2.errors"
                    required="required">
            </div>
        </div>
        <div class="textareas">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label class="with-help">{{'Text on Greeting Card' | translate}} <app-help-question-mark [text]="'Indicate in case greeting card'"></app-help-question-mark></label>
                    <textarea 
                        type="text" 
                        formControlName="card"
                        rows="6"></textarea>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label>{{'Comment' | translate}}</label>
                    <textarea 
                        type="text" 
                        formControlName="comment"
                        rows="6"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group create-account">
                <input id="agree" formControlName="agree" type="checkbox" [checked]="form.value.agree">
                <label for="agree">
                    {{ 'You agree with our' | translate }} <a class="link" (click)="onOpenPage('/terms')">{{ 'Terms & Conditions' | translate }}</a>
                </label>
            </div>
        </div>
    </div>
</form>