import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'slidshow',
  path_id: 'slidshow_id',
})

export class Slidshow extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'link1',
  })
  public link1!: string;

  @Field({
    is_required: true,
    field_name: 'link2',
  })
  public link2!: string;

  @Field({
    is_required: true,
    field_name: 'link3',
  })
  public link3!: string;
}
