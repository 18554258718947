import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartListingComponent } from './cart-listing.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes';

@NgModule({
  declarations: [CartListingComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule
  ],
  exports: [CartListingComponent]
})
export class CartListingModule { }
