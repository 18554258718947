import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Currency } from 'app/models';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currency: any = {};
  private _currency = new BehaviorSubject<any>(this.currency);
  currency$ = this._currency.asObservable();

  currentCurrency: string = 'usd';
  private _currentCurrency = new BehaviorSubject<string>(this.currentCurrency);
  currentCurrency$ = this._currentCurrency.asObservable();

  constructor() {

  }

  getCurrencies() {
    Currency.getAll().then(res => {
        if (!res) { return; }
        this.currency = res[0];
        this._currency.next(this.currency);
    });
  }

  changeCurrency(currency: string) {
    this.currentCurrency = currency;
    this._currentCurrency.next(this.currentCurrency);
  }
}