<footer class="footer">
  <!-- Footer Top -->
  <div class="footer-top section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-12">
          <!-- Single Widget -->
          <div class="single-footer about">
            <div class="logo">
              <a href="index.html"><img src="/assets/images/logo-footer.png" alt="#"></a>
            </div>
            <p class="text">{{ contact['about_' + lang] }}</p>
          </div>
          <!-- End Single Widget -->
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Single Widget -->
          <div class="single-footer links">
            <h4>{{ 'Information' | translate }}</h4>
            <ul>
              <li><a [routerLink]="['/about']">{{ 'MENU.About Us' | translate }}</a></li>
              <li><a [routerLink]="['/terms']">{{ 'Terms & Conditions' | translate }}</a></li>
            </ul>
          </div>
          <!-- End Single Widget -->
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Single Widget -->
          <div class="single-footer social">
            <h4>{{ 'Get In Tuch' | translate }}</h4>
            <!-- Single Widget -->
            <div class="contact">
              <ul>
                <li>{{ contact['address_' + lang] }}</li>
                <li>{{ contact.address2 }}</li>
                <li>{{ contact.email }}</li>
                <li>{{ contact.phone }}</li>
              </ul>
            </div>
            <!-- End Single Widget -->
            <ul style="display: flex;">
              <li><a (click)="goToLink(contact.fb)"><i class="ti-facebook"></i></a></li>
              <li><a (click)="goToLink(contact.instagram)"><i class="ti-instagram"></i></a></li>
              <li><a (click)="goToLink(contact.etsy)"><img src="/assets/images/etsy.png" alt="no icon"></a></li>
            </ul>
          </div>
          <!-- End Single Widget -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Footer Top -->
  <div class="copyright">
    <div class="container">
      <div class="inner">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="left">
              <p>{{ 'Copyright' | translate }} © 2020 <a (click)="goToLink('https://www.facebook.com/skamitech/')">Skami Tech • სკამი ტექ</a>  -  {{ 'All Rights Reserved' | translate }}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="right">
              <img src="/assets/images/payments.png" alt="#">
              <img src="/assets/images/paypal.png" class="payment-icon" alt="#">
              <img src="/assets/images/cash.png" class="payment-icon" alt="#">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>