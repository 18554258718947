import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderContainer } from './header.container';
import { SidebarModule } from '../sidebar/sidebar.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ProductModalModule } from 'app/components';
import { PipesModule } from '@pipes';

@NgModule({
  declarations: [HeaderContainer],
  imports: [
    CommonModule,
    SidebarModule,
    TranslateModule,
    RouterModule,
    ProductModalModule,
    PipesModule
  ],
  exports: [HeaderContainer]
})
export class HeaderModule { }
