import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpQuestionMarkComponent } from './help-question-mark.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HelpQuestionMarkComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [HelpQuestionMarkComponent]
})
export class HelpQuestionMarkModule { }
