import { Component, OnInit, Input } from '@angular/core';
import { Blog } from '@models';
import { HelperService } from 'app/services';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  @Input() lang: string = 'en';
  blogs: Blog[] = [];

  constructor() { }

  ngOnInit(): void {
    Blog.getAll().then(res => {
      this.blogs = res;
    });
  }

  goToLink(link: string) {
    HelperService.goToLink(link);
  }

}
