import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'blog',
  path_id: 'blog_id',
})

export class Blog extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'name_ge',
  })
  public name_ge!: string;

  @Field({
    is_required: true,
    field_name: 'name_en',
  })
  public name_en!: string;

  @Field({
    is_required: true,
    field_name: 'name_es',
  })
  public name_es!: string;

  @Field({
    is_required: true,
    field_name: 'image',
  })
  public image!: string;

  @Field({
    is_required: true,
    field_name: 'link',
  })
  public link!: string;
}
