import { Component, OnInit, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Category, Product, ProductOption, Color } from '@models';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CartService, CurrencyService } from '@services';
import { INavItem } from '@interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.container.html',
  styleUrls: ['./header.container.scss']
})
export class HeaderContainer implements OnInit {
  @Input() lang: string = 'en';
  profile: any = {};
  onShowAllCat: boolean = false;
  sticky: boolean = false;
  showMobileSearch: boolean = false;
  showMobileMenu: boolean = false;
  categories: Category[] = [];
  categoryTr: string = 'name_' + this.lang;
  selectedCat: any = 'all';

  currencyDropDown: boolean = false;
  currencies: any = {};
  selectedCurrency: string = 'usd';
  currencyOption: any = {
    gel: '₾',
    usd: '$',
    euro: '€'
  }

  cartItems: any[] = [];

  selectedProduct: any = {};
  productModal: boolean = false;

  searchProductList: any[] = [];

  navigation: Array<INavItem> = [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: 'Shop',
      url: '/shop'
    },
    {
      label: 'Featured',
      url: '/',
      className: 'product-area'
    },
    {
      label: 'About Us',
      url: '/about'
    },
    {
      label: 'Blog',
      url: '/',
      className: 'shop-blog'
    }
  ];

  private searchSubject: Subject<string> = new Subject();
  colors: Color[];
  cartTotalPrice: number = 0;

  constructor(public translate: TranslateService,
              private cartService: CartService,
              private router: Router,
              private currencyService: CurrencyService) {
    this.currencyService.getCurrencies();
    
    Category.getAll().then(cat => {
      this.categories = [...cat];
    });

    Color.getAll().then(col => {
      this.colors = col;
    })

    const cart = localStorage.getItem('credoraCart') && JSON.parse(localStorage.getItem('credoraCart'));
    if (cart) {
      this.cartService.getCartProducts(cart);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.sticky = window.pageYOffset > 172;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.id != 'search-result' || event.target.id != 'search-result-item') {
      this.searchProductList = [];
    }

    if (event.target.id !== 'cat-dropdown' && event.target.id !== 'current') {
      this.onShowAllCat = false;
    }
  }

  ngOnInit(): void {
    this.categoryTr = 'name_' + this.lang;

    this.cartService.cart$.subscribe(res => {
      this.cartItems = [];
      this.cartTotalPrice = 0;
      Object.keys(res).forEach(key => {
        this.cartItems.push({ ...res[key], optionId: key });
        this.cartTotalPrice += res[key].price * res[key].qty
      });
    });

    this.searchSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(async resp => {
        if (resp.length < 2) { return; }

        const products = await Product.getAll();

        this.searchProductList = [...this.searchProductList, ...products.filter(prod => prod.name_en.toLowerCase().includes(resp.toLowerCase()) ||
          prod.name_ge.toLowerCase().includes(resp.toLowerCase()) ||
          prod.name_es.toLowerCase().includes(resp.toLowerCase()) ||
          prod.desc_en.toLowerCase().includes(resp.toLowerCase()) ||
          prod.desc_es.toLowerCase().includes(resp.toLowerCase()) ||
          prod.desc_es.toLowerCase().includes(resp.toLowerCase()))];
          console.log(this.searchProductList);

          if (this.selectedCat !== 'all') {
            this.searchProductList = this.searchProductList.filter(prod => prod.category === this.selectedCat.id);
          }
          

        const productOptions = await ProductOption.getAll();
        const optionsProds = productOptions.filter(opti => opti.code.toLowerCase().includes(resp.toLowerCase()));
        for (const optionsProd of optionsProds) {
          if (!this.searchProductList.find(produc => produc.id === optionsProd.product)) {
            const product = products.find(pr => pr.id === optionsProd.product);
            if (this.selectedCat === 'all') {
              this.searchProductList.push(product);
            } else if (this.selectedCat.id === product.category) {
              this.searchProductList.push(product);
            }
          }
        }
      });

  }

  outClickedMenu(item: INavItem): void {
    this.router.navigate(['/' + item.url])
    setTimeout(() => {
      if (item.className) {
        const htmlElement: HTMLElement = document.querySelector(`.${item.className}`);
        
        if (htmlElement) {
          htmlElement.scrollIntoView({behavior: "smooth", block:"center"});
        }
      }
    }, 500)
  }

  onChangeCurrency(currency: string) {
    this.selectedCurrency = currency;
    this.currencyService.changeCurrency(currency);
  }

  async getSelectedProduct(product) {
    this.selectedProduct = product;
    this.productModal = true;
  }

  getColorName(colorId: string) {
    console.log(this.colors, colorId);
    if (!colorId) { return; }
    return this.colors.find(col => col.id === colorId)['name_' + this.lang];
  }

  onChooseCategory(cat: Category | string): void {
    this.selectedCat = cat;
  }

  onChangeLang(lang: string): void {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    switch (lang) {
      case 'ge':
        this.onChangeCurrency('gel');
        break;

      case 'en':
        this.onChangeCurrency('usd');
        break;
    
      default:
        this.onChangeCurrency('euro');
        break;
    }
  }

  onRemoveFromCart(optionId: string) {
    this.cartService.removeFromCart(optionId);
  }

  onSearch(e: any): void {
    console.log(e);
    
    this.searchProductList = [];
    this.searchSubject.next(e.target.value)
  }

}
