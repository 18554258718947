import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterContainer } from './footer.container';
import { SidebarModule } from '../sidebar/sidebar.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FooterContainer],
  imports: [
    CommonModule,
    SidebarModule,
    TranslateModule,
    PipesModule,
    RouterModule
  ],
  exports: [FooterContainer]
})
export class FooterModule { }
