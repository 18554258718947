import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'productOption',
  path_id: 'productOption_id',
})

export class ProductOption extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'price',
  })
  public price!: number;

  @Field({
    is_required: true,
    field_name: 'quantity',
  })
  public quantity!: number;

  @Field({
    is_required: true,
    field_name: 'color',
  })
  public color!: string | null;

  @Field({
    is_required: true,
    field_name: 'product',
  })
  public product!: string;

  @Field({
    is_required: true,
    field_name: 'code',
  })
  public code!: string;

}
