import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {}

  public static isObjectEmpty(object: any): boolean {
    if (!object) return true;
    return !!(Object.entries(object).length === 0 && object.constructor);
  }

  public static goToLink(link: string, target: string = '_blank'): void {
    window.open(link, target);
  }

  // Turn enum into object
  public static enumToObject(enumme: any): any {
    const enumObj = {};
    Object.keys(enumme)
      .forEach(key => enumObj[enumme[key]] = key);
    
    return enumObj;
  }
}
