import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProductOption } from '@models';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() title: string = '';
  @Input() lang: string = 'en';
  @Input() products: any[] = [];
  @Output() clickView = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onView(id: string): void {
    this.clickView.emit(id);
  }

}
