import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '@services';
import { Contact } from '@models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.container.html',
  styleUrls: ['./footer.container.scss']
})
export class FooterContainer implements OnInit {
  @Input() lang: string = 'en';
  profile: any = {};

  contact: any = {
    fb: 'https://www.facebook.com/Creadora-კრეადორა-422216018526401/',
    instagram: 'https://www.instagram.com/creadora.shop/',
    etsy: 'https://www.etsy.com/shop/CreadoraGeorgia?ref=seller-platform-mcnav',
  }

  constructor(
  ) {
  }

  async ngOnInit() {
    const contactDetails = await Contact.getAll();
    this.contact = { ...this.contact, ...contactDetails[0]['data'] };
  }

  goToLink(link: string): void {
    HelperService.goToLink(link);
  }
}
