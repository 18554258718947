<div class="row">
    <div class="col-12">
        <div class="shop-section-title">
            <h1>{{ title | translate }}</h1>
        </div>
    </div>
</div>
<!-- Start Single List  -->
<div class="single-list" *ngFor="let product of products">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <div class="list-image overlay">
                <span class="sale" *ngIf="product.sale">{{product.sale}}% Off</span>
                <img src="{{product.mainImage}}" alt="No Image">
                <a (click)="onView(product.id)" class="buy"><i class="fa fa-eye"></i></a>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12 no-padding">
            <div class="content">
                <h4 class="title"><a (click)="onView(product.id)">{{ product.name_en }}</a></h4>
                <p class="price with-discount">{{ product.price | currency }}</p>
            </div>
        </div>
    </div>
</div>
<!-- End Single List  -->