import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'currency',
  path_id: 'currency_id',
})

export class Currency extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'gel',
  })
  public gel!: number;

  @Field({
    is_required: true,
    field_name: 'usd',
  })
  public usd!: number;

  @Field({
    is_required: true,
    field_name: 'euro',
  })
  public euro!: number;
}
