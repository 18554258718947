import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductOption, Product } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart: any = {};
  private _cart = new BehaviorSubject<any>(this.cart);
  cart$ = this._cart.asObservable();

  constructor() {
  }

  getCartProducts(cart: any) {
    Object.keys(cart).forEach(async key => {
        if (!this.cart.hasOwnProperty(key)) {
          const productOpt = await ProductOption.init(key);
          if (!productOpt) return;
          const product = await Product.init(productOpt.product);
          this.cart[key] = { 
            ...product, 
            price: productOpt.price, 
            productQty: productOpt.quantity, 
            qty: cart[key], 
            color: productOpt.color 
          };
          this._cart.next(this.cart);
        }
      }
    );
  }

  removeFromCart(optionId: string) {
      delete this.cart[optionId];
      const cart = JSON.parse(localStorage.getItem('credoraCart'));
      delete cart[optionId];
      localStorage.setItem('credoraCart', JSON.stringify(cart));
      this._cart.next(this.cart);
  }

  clearCart() {
    this.cart = {};
    this._cart.next(this.cart);
  }
}