import { Component, OnInit, Input } from '@angular/core';
import { INavItem } from '@interfaces';
import { Router } from '@angular/router';
import { Category } from 'app/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.container.html',
  styleUrls: ['./sidebar.container.scss'],
})

export class SidebarContainer implements OnInit {
  @Input() lang: string = 'en';
  navigation: Array<INavItem> = [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: 'Shop',
      url: '/shop'
    },
    {
      label: 'Featured',
      url: '/',
      className: 'product-area'
    },
    {
      label: 'About Us',
      url: '/about'
    },
    {
      label: 'Blog',
      url: '/',
      className: 'shop-blog'
    }
  ];

  categories: Category[] = [];

  constructor(public router: Router) {
    Category.getAll().then(cat => {
      this.categories = [...cat];
    });
  }

  ngOnInit(): void {
  }

  onScrollSection(item: INavItem): void {
    this.router.navigate(['/' + item.url])
    setTimeout(() => {
      if (item.className) {
        const htmlElement: HTMLElement = document.querySelector(`.${item.className}`);
        
        if (htmlElement) {
          htmlElement.scrollIntoView({behavior: "smooth", block:"center"});
        }
      }
    }, 500)
  }

}
