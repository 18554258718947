import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Product, ProductOption } from '@models';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @Input() lang: string = 'en';
  @Input() product: Product;
  price: number = 0;
  nameTr: string;
  @Output() clickView = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.nameTr = 'name_' + this.lang;
    ProductOption.find('product','==',this.product.id).then(productOptions => {
      this.price = productOptions[0].price;
    })
  }

  onView(id: string): void {
    this.clickView.emit(id);
  }

}
