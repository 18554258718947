import { Field, BaseModel, Model } from '@arbel/firebase-orm/dist';

@Model({
  reference_path: 'contact',
  path_id: 'contact_id',
})

export class Contact extends BaseModel {
  @Field({
    is_required: true,
    field_name: 'phone',
  })
  public phone!: string;

  @Field({
    is_required: true,
    field_name: 'email',
  })
  public email!: string;

  @Field({
    is_required: true,
    field_name: 'address_ge',
  })
  public address_geo!: string;

  @Field({
    is_required: true,
    field_name: 'address_en',
  })
  public address_eng!: string;
  
  @Field({
    is_required: true,
    field_name: 'address_es',
  })
  public address_es!: string;

  @Field({
    is_required: true,
    field_name: 'about_ge',
  })
  public about_geo!: string;

  @Field({
    is_required: true,
    field_name: 'about_en',
  })
  public about_eng!: string;
  
  @Field({
    is_required: true,
    field_name: 'about_es',
  })
  public about_es!: string;

  @Field({
    is_required: false,
    field_name: 'outside_shipping',
  })
  public outside_shipping!: number;

  @Field({
    is_required: false,
    field_name: 'inside_shipping',
  })
  public inside_shipping!: number;

  @Field({
    is_required: false,
    field_name: 'paypal',
  })
  public paypal!: string;

}