import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemComponent } from './product-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes';

@NgModule({
  declarations: [ProductItemComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule
  ],
  exports: [ProductItemComponent]
})
export class ProductItemModule { }
