<div class="single-product" >
    <div class="product-img">
        <a (click)="onView(product.id)">
            <img class="default-img" [src]="product.mainImage" alt="#">
            <img class="hover-img" [src]="product.mainImage" alt="#">
            <span class="out-of-stock" *ngIf="product.featured">{{ 'Featured' | translate }}</span>
            <span class="price-dec" *ngIf="product.sale">{{product.sale}}% Off</span>
        </a>
        <div class="button-head">
            <div class="product-action">
                <a data-toggle="modal" data-target="#exampleModal" title="Quick View" (click)="onView(product.id)"><i class=" ti-eye"></i><span>{{'quick_shop' | translate}}</span></a>
                <!-- <a title="Wishlist" href="#"><i class=" ti-heart "></i><span>{{'add_to_wishlist' | translate}}</span></a> -->
            </div>
            <!-- <div class="product-action-2">
                <a title="Add to cart" href="#">Add to cart</a>
            </div> -->
        </div>
    </div>
    <div class="product-content">
        <h3><a (click)="onView(product.id)">{{ product[nameTr] }}</a></h3>
        <div class="product-price">
            <span>{{ price | currency }}</span>
        </div>
    </div>
</div>