import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency/currency.pipe';
import { ArraySortPipe } from './sort/sort.pipe';

@NgModule({
  declarations: [CurrencyPipe, ArraySortPipe],
  exports: [
    CurrencyPipe,
    ArraySortPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
