<div class="header-inner">
    <div class="container">
        <div class="cat-nav-head">
            <div class="row">
                <div class="col-lg-3" *ngIf="router.url === '/'">
                    <div class="all-category">
                        <h3 class="cat-heading"><i class="fa fa-bars" aria-hidden="true"></i>{{ 'Categories' | translate }}</h3>
                        <ul class="main-category">
                            <li *ngFor="let cat of categories">
                                <a [routerLink]="['/shop']" [queryParams]="{ category: cat.id }">
                                {{ cat['name_' + lang] }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-9 col-12">
                    <div class="menu-area">
                        <!-- Main Menu -->
                        <nav class="navbar navbar-expand-lg">
                            <div class="navbar-collapse">	
                                <div class="nav-inner">	
                                    <app-navigation 
                                        [navigation]="navigation" 
                                        (outClickedItem)="onScrollSection($event)"
                                    ></app-navigation>
                                </div>
                            </div>
                        </nav>
                        <!--/ End Main Menu -->	
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>