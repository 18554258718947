import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help-question-mark',
  templateUrl: './help-question-mark.component.html',
  styleUrls: ['./help-question-mark.component.scss']
})
export class HelpQuestionMarkComponent implements OnInit {
  @Input() text: string;
  @Input() leftDirection: false;
  help: any = {};

  constructor() {
   }

  ngOnInit(): void {
  }
}
