import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperContainer } from './wrapper.container';

import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    WrapperContainer
  ],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    FooterModule
  ],
  exports: [
    WrapperContainer
  ]
})

export class WrapperModule { }
